import React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import Metas from "../components/Metas";
import Title from "../components/Title";

const NotFoundPage = () => {
    return (
        <Layout>
            <Metas title="Page non trouvée"/>
            <section className="section page-content">
                <div className="container mx-auto px-4 lg:max-w-screen-lg">
                    <Title
                        title="Page non trouvée"
                        className="text-primary"
                        typesense={false}
                    />

                    <div className="block content mb-8">
                        <p>La page que vous recherchez n'a pas été trouvée.</p>
                    </div>

                    <div className="buttons">
                        <Link to="/" className="button button-primary">Retour à l'accueil</Link>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default NotFoundPage
