import React from "react";
import PropTypes from "prop-types";

const Title = ({title, subtitle, typesense, ...props}) => {
  if (props.className === undefined) {
    props.className = 'page-title'
  } else {
    props.className += ' page-title'
  }

  return (
    <div {...props}>
      <h1 className="title h1" data-typesense-field="title">{title}</h1>
      {subtitle && (
        <div className="subtitle">{subtitle}</div>
      )}
    </div>
  )
}

Title.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  typesense: PropTypes.bool
}

Title.defaultProps = {
  title: null,
  subtitle: null,
  typesense: true
}

export default Title
